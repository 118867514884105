<template>
  <v-dialog v-model="dialog"
            width="900px">
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $tc('label.anexo', 2) }}</span>
      </v-card-title>
      <v-card-text>
        <list-files
          @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
          :habilitar-exclusao="podeCadastrarAnexos"
          colLg="4"
          ref="listFiles"
          :uri="uri">
        </list-files>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="cancel" color="primary darken-1" text @click.native="close">
          {{ $t('label.fechar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ListFiles from '../../shared-components/files/ListFiles';
import basePath from '../../common/functions/api-resource';

export default {
  name: 'UsuarioAnexosDialog',
  components: {
    ListFiles,
  },
  props: {
    podeVisualizarAnexos: Boolean,
    podeCadastrarAnexos: Boolean,
  },
  data() {
    return {
      dialog: false,
      usuario: null,
    };
  },
  computed: {
    uri() {
      const urlUpload = basePath('job', 'arquivo/usuario');
      return this.usuario ? `${urlUpload}/${this.usuario.id}` : '';
    },
  },
  methods: {
    open(usuario) {
      this.usuario = usuario;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
